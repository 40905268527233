<template>
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <form class="form-horizontal" role="form" @submit.prevent="submitForm">
            <b-form-group
              id="platform"
              label-cols-sm="2"
              label-cols-lg="2"
              :label="$t('platforms.platform')"
              label-for="platform"
              :invalid-feedback="titleValidationMessage"
              :state="!isTitleValid ? false : null"
            >
              <b-form-input
                id="platform"
                v-model="item.title"
                @input="validateTitleField"
                :state="!isTitleValid ? false : null"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="description"
              label-cols-sm="2"
              label-cols-lg="2"
              :label="$t('platforms.platformDescription')"
              label-for="description"
              :invalid-feedback="descriptionValidationMessage"
              :state="!isDescriptionValid ? false : null"
            >
              <b-form-input
                id="description"
                v-model="item.description"
                @input="validateDescriptionField"
                :state="!isDescriptionValid ? false : null"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="prefix"
              label-cols-sm="2"
              label-cols-lg="2"
              :label="$t('platforms.prefixDescription')"
              label-for="prefix"
              :invalid-feedback="prefixValidationMessage"
              :state="!isPrefixValid ? false : null"
            >
              <b-form-input
                id="prefix"
                v-model="item.prefix"
                @input="validatePrefixField"
                :state="!isPrefixValid ? false : null"
              ></b-form-input>
            </b-form-group>

            <b-button
              variant="primary"
              type="submit"
              :disabled="formValidationButton"
            >{{ $t('buttons.save') }}</b-button>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        formValidationButton: true,
        isTitleValid: true,
        isDescriptionValid: true,
        isPrefixValid: true,
        titleValidationMessage: null,
        descriptionValidationMessage: null,
        prefixValidationMessage: null,
      };
    },
    props: {
      item: {
        type: Object,
        default: () => ({
          title: {
            type: String,
            default: '',
          },
          description: {
            type: String,
            default: '',
          },
          prefix: {
            type: String,
            default: '',
          },
          is_active: {
            type: Boolean,
            default: true,
          },
        }),
      },
      type:{
        type:String,
        default:''
      }
    },
    watch: {
      'item'() {
        this.validateForm();
      },
    },
    computed: {
      isFormValid() {
        return this.isTitleValid && this.isDescriptionValid;
      },
    },
    methods: {
      validateTitleField() {
        this.isTitleValid = this.item.title !== null && this.item.title.length > 0;
        this.titleValidationMessage = this.isTitleValid ? '' : this.$t('error.validation.platformNameRequaerd');
        this.validateForm()
      },
      validateDescriptionField() {
        this.isDescriptionValid = this.item.description !== null && this.item.description.length > 0;
        this.descriptionValidationMessage = this.isDescriptionValid ? '' : this.$t('error.validation.platformDescriptionRequaerd');
        this.validateForm()
      },
      validatePrefixField() {
        this.isPrefixValid = this.item.prefix !== null && this.item.prefix.length > 0;
        this.prefixValidationMessage = this.isPrefixValid ? '' : this.$t('error.validation.prefixDescriptionRequaerd');
        this.validateForm()
      },
      validateForm() {
        var isTitleValid = this.item.title !== null && this.item.title.length > 0;
        var isDescriptionValid = this.item.description !== null && this.item.description.length > 0;
        var isPrefixValid = this.item.prefix !== null && this.item.prefix.length > 0;
        this.formValidationButton = !(isTitleValid && isDescriptionValid && isPrefixValid);
      },
      submitForm() {
        if (this.isFormValid) {
          const json = JSON.stringify({
            title: this.item.title,
            description: this.item.description,
            prefix: this.item.prefix,
            is_active: true,
          });
          this.$emit('data', json);
        }
      },
    },
    mounted() {
      if (this.type == "CREATE"){
        this.isTitleValid = true
        this.isDescriptionValid = true
        this.titleValidationMessage = ""
        this.descriptionValidationMessage = ""
        this.formValidationButton = true
      }
    }
  };
  </script>